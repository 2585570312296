$header: 56px;
$footer: 71px;
.container {
  margin: 1rem 1rem 0 1rem;
  min-height: calc(100vh - #{$header} - #{$footer} - 1rem); // 上マージン: 1rem
}

$workPlanHeight: calc(#{$header} + #{$footer} + 1.5rem); // 上下マージン: 0.75rem * 2
.workPlanContainer {
  display: flex;
  max-height: calc(100vh - (#{$workPlanHeight}));
  margin-bottom: 0.75rem;
}

.cardContainer {
  margin-right: 1rem;
  overflow-y: auto;
}

.workPlanCard {
  margin-bottom: 0.5rem;
}

.workPlanCard:last-child {
  margin-bottom: 0;
}

.viewWorkButton {
  line-height: 1 !important;
}

.w20 {
  width: 20%;
}

.w80 {
  width: 80%;
}

$inputFieldHeight: 38px;
$iconButtonHeight: 30px;
.tableButtonGroup {
  height: $iconButtonHeight;
}

.iconButtonContainer {
  margin-top: ($inputFieldHeight - $iconButtonHeight) * 0.5;
}

$cardPadding: 3.75rem;
$filterHeight: 38px;
$filterPadding: 0.5rem;

.openFilterTableHeight {
  max-height: calc(100vh - (#{$workPlanHeight}) - #{$cardPadding} - #{$filterHeight} * 2 - #{$filterPadding});
}
.closeFilterTableHeight {
  max-height: calc(100vh - (#{$workPlanHeight}) - #{$cardPadding} - #{$filterHeight});
}
