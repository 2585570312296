.list {
  height: 100%;
  padding: 0.8rem;
  overflow-y: auto;
}

.row {
  height: calc(100vh - 56px - 38px - 3rem); // 56px: ヘッダー, 38px: タイトル, マージン合計: 3rem
  & > *:nth-child(n + 2) {
    padding-left: 0;
  }
}

.placeholderImage {
  max-width: 320px; // 他画面でのサイズ指定と統一する
}
