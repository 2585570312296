.row {
  height: calc(100vh - 56px - 38px - 3rem); // 56px: ヘッダー, 38px: タイトル, マージン合計: 3rem
  & > *:nth-child(n + 2) {
    padding-left: 0;
  }
}

.list {
  padding: 0.8rem;
  overflow-y: auto;
}

$footer: 70px;
.shiftDetail {
  height: calc(100% - #{$footer});
  overflow-y: auto;

  .top {
    height: 77px;
  }

  .workerSchedule {
    height: calc(100% - 77px - 1rem);
    overflow-y: auto;
  }
}

.placeholderImage {
  max-width: 320px;
}
