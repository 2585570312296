.list {
  padding: 0.8rem;
  overflow: auto;
}

.placeholder {
  img {
    width: 240px;
    max-width: 320px;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
  }

  p {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.row {
  height: calc(100vh - 56px - 38px - 3rem); // 56px: ヘッダー, 38px: タイトル, マージン合計: 3rem
  & > *:nth-child(n + 2) {
    padding-left: 0;
  }
}
