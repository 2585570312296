.header {
  height: 71px;
  background-color: white;
  border-bottom: solid 2px var(--bs-secondary-pale);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.list {
  top: 71px;
  height: calc(100vh - 71px);
  padding: 1.6rem 0.8rem 0.8rem;
  overflow: auto;
  background-color: white;
}

.singleTextCard {
  line-height: 48px;
}
