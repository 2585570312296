.activeItem {
  background-color: var(--bs-secondary-pale) !important;
  &:hover {
    background-color: var(--bs-secondary-pale) !important;
  }
}
.item {
  &:active {
    background-color: var(--bs-primary) !important;
  }
}

.icon {
  position: absolute;
  display: inline-block;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
}

.noCursor {
  pointer-events: none;
}
